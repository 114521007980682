html
{
  // min-height: 100vh;
  min-width: 100vw;
  margin: 0 0 0 0;
  padding:0 0 0 0;
}


.slug-page
{
  min-width: 99vw;
  min-height: 99vh;
  // min-height: 100vh;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  background-color: #ffffff;

  #root, iframe
  {
    min-width: 99vw;
    min-height: 99vh;
    margin: 0 0 0 0;
    padding: 0 0 0 0;  

    .backdrop
    {
      display: none;
    }

  }

}


.tab-user-card {
  .nav-pills {
    background: #ecf0f5;
    padding: 20px;
    border-radius: 5px;
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button.active{
  background: $primary-color;
  border-color: $primary-color;
}

.q-view{
  width: 100vw;
  left: 0;
  &.active{
    .content {
      left: auto;
      position: fixed;
      .media-object {
        margin-bottom: 10px;
      }
    }
  }
}

.help-desk {
  .media-object {
    margin-bottom: 10px;
  }
}

.navbar-wrapper {
  .navbar-content {
    &.next-scroll {
      height: 100vh;
    }
  }
}


.resumePreview
{
  height:350px;
  width: 300px;
  overflow: hidden;


  .overlayContainer
  {
    position: relative;
    
    .resumePreviewWindow
    {
      height:800px;
      width:600px;
    
      -ms-zoom: 0.4;
      -moz-transform: scale(0.4);
      -moz-transform-origin: 0 0;
      -o-transform: scale(0.4);
      -o-transform-origin: 0 0;
      -webkit-transform: scale(0.4);
      -webkit-transform-origin: 0 0;
    }

    .overlay
    {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100%;
      width: 100%;
      cursor: pointer;
    }

  }


}

.autocompleteWidget
{
  position: relative;

  .autocompleteContainer
  {
    position: absolute;
    top: 50px;
    left: 0px;
    border: solid 1px #D9D9D9;
    border-radius: 5px;
    margin: 0px;
    padding: 11px 12px;
    z-index: 100;
    background-color: #ffffff;

    .autocompleteItem
    {
      padding: 3px 10px;
      cursor: pointer;

      &:hover
      {
        background-color: #1A73E8;
        color: #ffffff;
      }

      &.selected
      {
        background-color: #1A73E8;
        color: #ffffff;
        font-weight: 500;
      }

    }

  }

}

@media only screen and (max-width: 1199px) {
  .hover-blk {
    display: none;
  }
}

.qview-modal {
  &.fade {
    .q-view-modal.modal-dialog {
      transform: translateX(550px);
    }
  }
  &.show {
    padding-right: 0 !important;
    .q-view-modal.modal-dialog {
      transform: none;
    }
  }
}
.q-view-modal {
  margin: 0 0 0 auto !important;
  .modal-content {
    min-height: 100vh;
    border-radius: 0;
    border: none;

    .modal-body {
      flex: 1 1 100%;
      height: 100%;

      .trumbowyg-box,
      .trumbowyg-editor {
        min-height: 110px;
      }
    }
  }
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 550px;
      margin: 0 0 0 auto;
    }
  }
}
.pcoded-header .dropleft .dropdown-toggle::before {
  display: none;
}
input.form-control[type='radio']{
  height: 25px;
}


.container {
	width: 1240px;
	max-width: 100%;
	padding-left: 50px;
	padding-right: 50px;
	margin: 0 auto;
}
.s-pt-half,
.s-py-half {
    padding-top: 50px !important;
}
.s-pb-half,
.s-py-half {
    padding-bottom: 50px !important;
}


.light-blue-bg {
	background: #F5FAFF;
  margin-top: 50px;
}


.contact-form {
	padding: 50px;
}
.disclaimer {
	font-size: 12px;
	padding-top: 30px;
	margin-top: 30px;
	border-top: 1px solid #DDDDDD;
}
.disclaimer p {
	font-size: 14px;
	line-height: normal;
}


.title {
	margin-bottom: 30px;
}
.title:last-child {
	margin-bottom: 0;
}


.form-msg {
	margin-bottom: 30px;
}
.form-msg .error-msg {
	padding: 10px;
	background: #fee;
	font-size: 14px;
	line-height: normal;
	color: #cd3333;
	border-radius:5px;
	-webkit-border-radius:5px;
	-moz-border-radius:5px;
}
.form-msg .success-msg {
	padding: 10px;
	background: #bfedcf;
	font-size: 14px;
	line-height: normal;
	color: #000;
	border-radius:5px;
	-webkit-border-radius:5px;
	-moz-border-radius:5px;
}
.text-field + .error-msg, .custom-select + .error-msg, .error-msg {
	margin-top: 5px;
	font-size: 14px;
	color: #cd3333 !important;
	line-height: normal;
}

.form-list.row {
	margin: 0 -8px;
	padding: 0;
}
.form-list {
	list-style: none;
}
.form-list li {
	width: 100%;
	padding: 0 8px;
	margin-bottom: 16px;
	position: relative;
}
.form-list li:last-child {
	margin-bottom: 0;
}
.form-list li.half {
	width: 50%;
}
.form-list li .row {
	margin: 0 -8px;
}
.form-list li .row .col,
.form-list li .row [class^="col-"] {
    padding-left: 8px;
    padding-right: 8px;
}
.text-field {
	width: 100%;
	font-family: 'Inter', sans-serif;
	font-size: 14px;
	font-weight: 400;
	color: #000;
	background: #fff;
	border: 1px solid #D9D9D9;
	padding: 14px 16px;
	border-radius:5px;
	-webkit-border-radius:5px;
	-moz-border-radius:5px;
	-webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-transition:all 0.3s ease-in-out;
    -moz-transition:all 0.3s ease-in-out;
    -o-transition:all 0.3s ease-in-out;
    transition:all 0.3s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
}
.text-field.error {
	border-color: #cd3333 !important;
	color: #cd3333 !important;
}
.text-field:focus {
	border-color: #1A73E8;
	outline: none;
	-webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.blue-btn {
	display: inline-block;
	min-width: 160px;
	font-size: 14px;
	line-height: 24px;
	font-weight: 500;
	color: #fff;
	text-align: center;
	padding: 7px 24px;
	background-color: #1A73E8;
	border: 1px solid #1A73E8;
	cursor: pointer;
	border-radius:5px;
    -webkit-border-radius:5px;
    -moz-border-radius:5px;
    -webkit-transition:all 0.3s ease-in;
    -moz-transition:all 0.3s ease-in;
    -o-transition:all 0.3s ease-in;
    transition:all 0.3s ease-in;
}
.blue-btn:focus,
.blue-btn:hover {
	color: #fff;
	background-color: #185abc;
	border-color: #185abc;
}

.site-footer {
	padding: 20px;
	border-top: 1px solid #ddd;
	font-size: 14px;
	color: #7C7C7C;
	line-height: 22px;
	text-align: center;
}


@media (max-width: 575px)
{
  .progress-block
  {
    margin-top:10px
  }

  .resume-step-left
  {
    width:100% !important;
    max-width: 100% !important;
    float:none;
    clear:both;
    flex-basis: unset !important;
    flex-grow: unset !important;


    .example-block
    {
      margin-top: 10px;
    }


    .example-box
    {
      height: auto;
    }

  }

  .resume-step-right
  {
    width:100% !important;
    max-width: 100% !important;
    flex-basis: unset !important;
    flex-grow: unset !important;

    .editor-block
    {
      margin-top:10px
    }

    .skill-holder
    {
      min-height:300px;
    }
  }

  .title
  {
    margin-bottom: 0px !important;
    
    .col
    {
      flex-basis: unset;
    }
  }

  .text-editor
  {
    height: 300px !important;
  }
}

.move-icon
{
  display: none !important;
}

.resume-step-temporary
{
  width: 100%;
}

.resume-step-left-temporary
{
  display:none;
}

.resume-step-right-temporary
{
  flex-direction: row;
  flex:none;
  width: 100% !important;
  max-width: 100%;
}

.resume-editor-temporary
{
  margin-top: 0px
}

.ck-editor__editable
{
  min-height: 400px;
}

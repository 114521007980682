.resume-builder
{
    font-family: 'Roboto', sans-serif;


    .container {
        width: 1240px;
        max-width: 100%;
        padding-left: 50px;
        padding-right: 50px;
        margin: 0 auto;

        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: space-between;
        justify-content: space-between;


        .logo {
            max-width: 106px;
            margin: 0 auto;
        }


        .inline-edit
        {

            .edit
            {
                font-size: 14px;
                color: #1A73E8;
                font-weight: normal;
                vertical-align: middle;
            }

        }

        .inline-edit-block
        {
            font-size: 0;

            .text-field 
            {
                display: inline-block;
                vertical-align: top;
                padding: 5px 10px;
                width: calc(100% - 100px);
                border-color: #1A73E8;
                border-radius: 0;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
            }

            .btn-cancel 
            {
                background: transparent;
                border:  none;
                height: 30px;
                padding: 5px 10px;
                font-size: 14px;
                color: #7c7c7c;
                cursor: pointer;
            }

            .btn-check 
            {
                position: relative;
                width: 30px;
                height: 30px;
                background: #1A73E8;
                border: 0;
                display: inline-block;
                vertical-align: top;
                cursor: pointer;

                &:after 
                {
                    font-family: 'fontello';
                    content: "\e818";
                    font-size: 10px;
                    color: #fff;
                    line-height: 30px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        

        .progress-block
        {

            .mobile-progress-title
            {
                display: none;
                font-size: 12px;
                line-height: normal;
                color: #1A73E8;                
            }

            .step
            {
                font-size: 12px;
                line-height: normal;
                color: #7C7C7C;
                position: relative;
                padding: 0 28px 0 22px;
                margin-right: 5px;
                cursor: default;
                display: inline-block;
                vertical-align: middle;
                

                &:before
                {
                    content: "";
                    font-family: 'fontello';
                    font-size: 6px;
                    line-height: 15px;
                    color: #fff;
                    text-align: center;
                    position: absolute;
                    top: -2px;
                    left: 0;
                    width: 18px;
                    height: 18px;
                    background: #fff;
                    border: 1px solid #ddd;
                    border-radius: 18px;
                    -webkit-border-radius: 18px;
                    -moz-border-radius: 18px;                    
                }
                

                &:after
                {
                    content: "";
                    position: absolute;
                    top: 7px;
                    right: 0;
                    width: 20px;
                    height: 1px;
                    background: #ddd;                    
                }


                &:last-child
                {
                    margin-right: 0;
                    padding-right: 0;                    
                }


                &.active
                {
                    color: #000;
                    
                    &:before
                    {
                        content: "\e818";
                        background: #1A73E8;
                        border-color: #1A73E8;                        
                    }
                }


                &.visited
                {
                    color: #000;

                    &:before
                    {
                        content: "\e818";
                        background: #51D757;
                        border-color: #51D757;
                    }
                }


            }

        }


        .close
        {
            position: absolute;
            top: 0;
            right: 0;
            background: #1A73E8;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 500;
            color: #fff;
            padding: 5px;
            opacity: 1;

            svg 
            {
                display: inline-block;
                vertical-align: middle;
                width: 15px;
                height: 15px;
                margin-right: 5px;
            }

        }


        .dropdown-custom
        {
            position: relative;
            font-size: 14px;
            color: #7C7C7C;
            text-align: left;
            display: inline-block;
            padding: 10px 30px 10px 15px;
            margin: 0 10px 0 0;
            border: 1px solid #DDD;
            cursor: pointer;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            transition: all 0.3s ease;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            width: 240px;

            &:hover 
            {
                color: #000;
            }
            
            &:after 
            {
                content: "";
                position: absolute;
                pointer-events: none;
                margin-top: -2px;
                top: 50%;
                right: 15px;
                width: 0;
                height: 0;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 4px solid #7C7C7C;
                transition: all 0.3s ease;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
            }

            .active 
            {
                color: #7C7C7C;
                border-color: #1A73E8;

                &:after 
                {
                    -webkit-transform: rotate(180deg);
                    -o-transform: rotate(180deg);
                    transform: rotate(180deg);
                }

            }

            span 
            {
                float: right;
                margin-top: 3px;
                font-size: 12px;
                color: #7C7C7C;
            }

        }
        
    }


    #colors
    {

        .template-colors 
        {

            li 
            {
                list-style: none;
                display: inline-block;
                margin: 5px 5px 5px 0;
        
                &:last-child 
                {
                    margin-right: 0;
                }
            
                &.active
                {
                    .color-name
                    {
                        &:after 
                        {
                            opacity: 1;
                        }
                    }
                }
    
                .color-name 
                {
                    display: block;
                    width: 42px;
                    height: 36px;
                    position: relative;
                    border: 1px solid transparent;
                    cursor: pointer;
                    border-radius: 5px;
                    -webkit-border-radius: 5px;
                    -moz-border-radius: 5px;
                    transition: all 0.3s ease;
                    -webkit-transition: all 0.3s ease;
                    -moz-transition: all 0.3s ease;
            
                    &:hover, &.active 
                    {
                        border-color: #1A73E8;
                    }
            
                    &:after 
                    {
                        font-family: 'fontello';
                        content: "\e818";
                        font-size: 7px;
                        line-height: 36px;
                        color: #e1dede;
                        text-align: center;
                        position: absolute;
                        left: 0;
                        width: 100%;
                        opacity: 0;
                        transition: all 0.3s ease;
                        -webkit-transition: all 0.3s ease;
                        -moz-transition: all 0.3s ease;
                    }
            
                    &:hover:after
                    {
                        opacity: 1;
                    }

                }
            }

        }

    }

    
    #formatting
    {
        .row
        {

            &.formatting-opt
            {
                margin-left: -30px;
                margin-right: -30px;

                .col, [class^="col-"]
                {
                    padding-left: 30px;
                    padding-right: 30px;
                }

                .col-3 { margin-bottom: 30px; }

                label
                {
                    font-weight: 400;
                    margin-bottom: 13px;
                }

                .select
                {
                    .select-styled
                    {
                        padding: 8px 15px;
    
                        &:after 
                        {
                            content: "";
                            position: absolute;
                            pointer-events: none;
                            margin-top: -2px;
                            top: 50%;
                            right: 15px;
                            width: 0;
                            height: 0;
                            border-left: 4px solid transparent;
                            border-right: 4px solid transparent;
                            border-top: 4px solid #7C7C7C;
                        }
                    }

                    .select-options 
                    {
                        display: none;
                        position: absolute;
                        z-index: 3;
                        top: 100%;
                        left: 0;
                        width: 100%;
                        max-height: 200px;
                        overflow-y: auto;
                        background: #fff;
                        border: 1px solid #ddd;
                        text-align: left;
    
                        &.reverse 
                        {
                            top: inherit;
                            bottom: 100%;
                        }
    
                        li 
                        {
                            font-size: 14px;
                            padding: 10px 15px;
                            margin: 0;
                            border-bottom: 1px solid #ddd;
                            transition: all 0.3s ease;
                            -webkit-transition: all 0.3s ease;
                            -moz-transition: all 0.3s ease;
        
                            &:hover 
                            {
                                background: #fafafa;
                            }
        
                            &:last-child 
                            {
                                border-bottom: 0;
                            }
                        }
    
                        .option-group 
                        {
                            border-bottom: 1px solid #ddd;
                            padding: 10px 0;
        
                            li 
                            {
                                padding: 5px 15px;
                                border-bottom: 0;
                            }

                        }

                    }

                }

                .slider-wrapper
                {
                    position: relative;
    
                    .label
                    {
                        position: absolute;
                        top: -10px;
                        left: calc(100% + 13px);
                        width: 50px;
                        font-size: 14px;
                        color: #1A73E8;
                    }

                    .ui-slider
                    {
                        position: relative;
                        text-align: left;
                    }

                }

                .ui-slider-horizontal 
                {
                    height: 2px;
                    background: #D9E5F1;
                    border: 0;
                    margin: 0;
                    border-radius: 3px;
                    -webkit-border-radius: 3px;
                    -moz-border-radius: 3px;
                }
                
                .ui-slider-handle 
                {
                    width: 8px;
                    height: 8px;
                    top: -4px;
                    margin: 0;
                    background: #1A73E8;
                    outline: 3px solid rgba(26, 115, 232, .3);
                    cursor: pointer;
                    border-radius: 8px;
                    -webkit-border-radius: 8px;
                    -moz-border-radius: 8px;
    
                    position: absolute;
                    z-index: 2;
                    width: 8px;
                    height: 8px;
                    cursor: default;
                    -ms-touch-action: none;
                    touch-action: none;

                    &:focus
                    {
                        outline: 3px solid rgba(26, 115, 232, .3);
                    }

                    &.ui-state-default
                    {
                        margin-left:-11px;
                    }

                }

                .ui-slider-pips
                {
                    .ui-slider-pip-label .ui-slider-label
                    {
                        display: none;
                    }

                    .ui-slider-pip
                    {
                        top: -2px !important;
                        width: 2em;
                        height: 1em;
                        line-height: 1em;
                        position: absolute;
                        font-size: 0.8em;
                        color: #999;
                        overflow: visible;
                        text-align: center;
                        top: 20px;
                        left: 20px;
                        margin-left: -1em;
                        cursor: pointer;
                        -webkit-touch-callout: none;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                    }

                    .ui-slider-line 
                    {
                        display: block;
                        width: 6px;
                        height: 6px;
                        background: #BDCBD9;
                        border-radius: 6px;
                        -webkit-border-radius: 6px;
                        -moz-border-radius: 6px;
                    }
                }                
 
            }

            .col
            {
                -ms-flex-preferred-size:0;
                flex-basis:0;
                -webkit-box-flex:1;
                -ms-flex-positive:1;
                flex-grow:1;
                max-width:100%;
            }

            .col-auto
            {
                -webkit-box-flex:0;
                -ms-flex:0 0 auto;
                flex:0 0 auto;
                width:auto;
                max-width:none;
            }

            .col-1 {width: 8.333333%;}
            .col-2 {width: 16.666667%;}
            .col-3 {width: 25%;}
            .col-4 {width: 33.333333%;}
            .col-5 {width: 41.666667%;}
            .col-6 {width: 50%;}
            .col-7 {width: 58.333333%;}
            .col-8 {width: 66.666667%;}
            .col-9 {width: 75%;}
            .col-10 {width: 83.333333%;}
            .col-11 {width: 91.666667%;}
            .col-12 { width: 100%; }            
        }
        
    }


    .page-final
    {
        font-family: "Calibri";
        width:100%;

        p
        {
            font-size: 16px;
            color: #333333;
            font-weight: 400;
            line-height: normal;
        }

        ul, li
        {
            margin:0;
            padding:0;
            border:0;
            outline:0;
            font-size:100%;
            font-weight:normal;
            vertical-align:top;
            background:transparent;            
        }

        span 
        {
            font-size: 16px;
            color: #333333;
            font-weight: 400;
            display: inline-block;
            line-height: normal;
        }

        a
        {
            color: #1A73E8;
            text-decoration: none;
            padding: 0;
            outline: none;
            transition: all 0.3s ease;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
    
            &:hover 
            {
                color: #7C7C7C;
                text-decoration: none !important;
            }

        }
        
        .space-between
        {
            justify-content: space-between;
            -webkit-justify-content: space-between;
            -ms-justify-content: space-between;
        }

        .row
        {

            .col, [class^="col-"] 
            {
                padding-left: 15px;
                padding-right: 15px;
            }
    
            &.no-gutters 
            {
                margin-left: 0;
                margin-right: 0;
        
                > .col, > [class^="col-"] 
                {
                    padding-left: 0;
                    padding-right: 0;
                }
            }

        }

        .col 
        {
            -ms-flex-preferred-size:0;
            flex-basis:0;
            -webkit-box-flex:1;
            -ms-flex-positive:1;
            flex-grow:1;
            max-width:100%;
        }

        .col-auto
        {
            -webkit-box-flex:0;
            -ms-flex:0 0 auto;
            flex:0 0 auto;
            width:auto;
            max-width:none;
        }

        .col-1 {width: 8.333333%;}
        .col-2 {width: 16.666667%;}
        .col-3 {width: 25%;}
        .col-4 {width: 33.333333%;}
        .col-5 {width: 41.666667%;}
        .col-6 {width: 50%;}
        .col-7 {width: 58.333333%;}
        .col-8 {width: 66.666667%;}
        .col-9 {width: 75%;}
        .col-10 {width: 83.333333%;}
        .col-11 {width: 91.666667%;}
        .col-12 {width: 100%;}

        .more-opt 
        {

            .opt-icon
            {
                display: inline-block;
                vertical-align: top;
                width: 32px;
                height: 32px;
                color: #7C7C7C;
                background-color: #F5FAFF;
                border-radius: 32px;
                -webkit-border-radius: 32px;
                -moz-border-radius: 32px;
        
                &:hover 
                {
                    background: #1A73E8;
                    color: #fff;
                }
        
                &:after 
                {
                    width: 100%;
                    height: 100%;
                    text-align: right;
                    font-family: 'fontello';
                    font-size: 14px;
                    line-height: 32px;
                    content: "\e801";
                    position: absolute;
                    top: 0;
                    left: 0;
                    padding-right: 13px;
                }

            }

            &.dropdown-menu
            {
                position: relative;
                padding: 0;
                opacity: 1;
                width:100%;
                text-align: right;
                border: none;
                top: 0px !important;
                transform: scale(1);
            }

        }

        .template-name
        {

            .inline-edit 
            {
                position: relative;
                display: inline-block;
        
                a 
                {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    top: 0;
                    left: 0;
                }

            }
    
            .inline-text 
            {
                position: relative;
                padding-left: 20px;
                color: #7C7C7C;
        
                &:after 
                {
                    font-family: 'fontello';
                    font-size: 14px;
                    content: "\e80b";
                    position: absolute;
                    top: 4px;
                    left: 0;
                }

            }

        }
        
        .template-header
        {

            .more-opt 
            {
                display: inline-block;
            }
    
            .dropdown-menu
            {
                .droupdown 
                {
                    left: inherit;
                    right: 0;
                    width: 100px;

                    &:before, &:after 
                    {
                        /*left: inherit;
                        right: ;*/
                    }
                }

            }

        }
        
        .template-header .right-block 
        {
            width: 40%;
        }

        .preview-weapper > .left-block 
        {
            width: 24%;
        }

        .preview-weapper > .right-block 
        {
            width: 75%;
        }

        .sidebar 
        {
            position: sticky;
            position: -webkit-sticky;
            top: 114px;

            .close 
            {
                display: none;
            }

            ul 
            {
                list-style: none;
                margin:0;
                padding:0;
                border:0;
                outline:0;
                font-size:100%;
                font-weight:normal;
                vertical-align:top;
                background:transparent;
        
                li 
                {
                    border-bottom: 1px solid #DDD;
                    position: relative;
        
                    a 
                    {
                        display: block;
                        padding: 15px 0;
                        color: #7C7C7C;
                        position: relative;
            
                        &:hover 
                        {
                            font-weight: 500;
                            color: #1A73E8;
                        }
                    }
        
                    &.icon a 
                    {
                        padding-left: 25px;
                    }
        
                    &.icon a:after 
                    {
                        font-family: 'fontello';
                        font-size: 13px;
                        position: absolute;
                        top: 15px;
                        left: 0;
                    }
        
                    &.spell-icon a:after 
                    {
                        content: "\e808";
                    }
        
                    &.plus-icon a:after 
                    {
                        content: "\e807";
                    }
        
                    &.download-icon a:after 
                    {
                        content: "\e806";
                    }
        
                    li
                    {
                        position: relative;
                        border: 0;
            
                        &:last-child 
                        {
                            margin-bottom: 12px;
                        }
                    }
        
                    h4 
                    {
                        margin: 16px 0 12px 0;
                        font-weight: 500;
                        color: #7C7C7C;
                    }
        
                    li a 
                    {
                        padding: 4px 0 4px 12px;
                    }
        
                    li a:after 
                    {
                        content: "";
                        width: 4px;
                        height: 4px;
                        position: absolute;
                        top: 14px;
                        left: 0;
                        background: #7C7C7C;
                        border-radius: 4px;
                        -webkit-border-radius: 4px;
                        -moz-border-radius: 4px;
                    }

                }

            }

        }

        .template-holder 
        {
            width: 962px;
            -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
            -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
            box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
        }
        
        .item-container 
        {
            position: relative;
    
            &:last-child .spacer 
            {
                display: none;
            }
    
            &:hover, &.active 
            {
                z-index: 4;
            }
    
            .inner-content 
            {
                position: relative;
                z-index: 2;
            }

            &:hover .move-icon, &.active .move-icon 
            {
                display: block;
            }
            
            &:hover .overlay, &:hover .action-holder, &.active .overlay, &.active .action-holder 
            {
                display: inline-block;
            }
            
        }

        .action-holder 
        {
            display: none;
            position: absolute;
            z-index: 2;
            top: -30px;
            left: -68px;
            bottom: -30px;
    
            .edit-icon, .delete-icon 
            {
                position: relative;
                width: 38px;
                height: 38px;
                display: block;
                text-align: center;
                color: #fff;
                border-radius: 0;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
            }
    
            .edit-icon:after,
            .delete-icon:after,
            .edit-icon:hover:after,
            .delete-icon:hover:after 
            {
                color: #fff;
            }
    
            .edit-icon 
            {
                background-color: #1A73E8;
            }
    
            .delete-icon 
            {
                background-color: #E53F3F;
            }

        }

        .move-icon 
        {
            position: absolute;
            z-index: 2;
            top: -25px;
            right: -25px;
            background: transparent;
            display: none;
    
            &:hover:after 
            {
                color: #1A73E8;
            }
        }
        
        .overlay 
        {
            display: none;
            position: absolute;
            left: -30px !important;
            right: -30px !important;
            top: -30px;
            bottom: -30px;
            background: #fff;
            border: 1px solid #d9d9d9;
            z-index: 1;
            -webkit-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
        }

        .parentcontainer
        {
            max-width: 962px;
            margin: 0 auto;
            width: 100%;
            background: #ffffff;

            .top-box 
            {
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                background: #F0F8FF;
                padding: 30px;
            }

            .right-box 
            {
                min-width: 35%;
                padding-left: 40px;
            }
    
        }

        .circled-logo 
        {
            width: 100px;
            height: 100px;
            
            svg text 
            {
                font-size: 64px;
                line-height: 60px;
            }
        }

        .info-block .name-block 
        {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            align-items: center;
            margin-bottom: 15px;
    
            .info-text
            {
                padding-left: 20px;
                vertical-align: middle;
            }

        }

        .top-box
        {

            .info-text
            {

                .name-text 
                {
                    font-size: 44px;
                    text-transform: capitalize;
                    color: #1A73E8;
                    line-height: 44px;
                }
        
                .sub-text 
                {
                    font-size: 22px;
                    text-transform: capitalize;
                    line-height: 22px;
                }

            }
    
            .left-block
            {
                width: 65%;
            }

            .right-block
            {
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -webkit-align-items: center;
                align-items: center;
                padding-left: 60px;
                min-width: 35%;
                position: relative;
        
                &::before 
                {
                    position: absolute;
                    content: '';
                    width: 1px;
                    height: 100%;
                    left: 30px;
                    background-color: #00000033; 
                }
        
                .contact-block
                {

                    .iconrow
                    {
                        display: -webkit-box;
                        display: -moz-box;
                        display: -ms-flexbox;
                        display: -webkit-flex;
                        display: flex;
                        -webkit-align-items: flex-start;
                        align-items: flex-start;
                        padding: 10px 0;
            
                        .iconsvg 
                        {
                            height: 20px;
                            width: 20px;
                            display: inherit;
                
                            img 
                            {
                                width: 100%;
                            }

                        }
            
                        .icontext 
                        {
                            margin-left: 10px;
                            color: #333333;
                            word-break: break-word;
                            width: calc(100% - 30px);
                        }

                    }

                }

            }

        }

        .iconrow.phone .iconsvg
         {
            width: 18px !important;
            height: 18px !important;
        }

        .iconrow.email .iconsvg 
        {
            width: 19px !important;
            height: 19px !important;
        }

        .content-block 
        {
            padding: 30px;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
        }

        .detail-block
        {

            .section-title 
            {
                font-weight: 700;
                color: #1A73E8;
                font-size: 28px;
                margin-bottom: 15px;
                text-transform: capitalize;
                line-height: 28px;
            }
    
            .heading-text
            {
                color: #333333;
                font-size: 22px;
                text-transform: capitalize;
                margin-bottom: 5px;
                line-height: 22px;
            }

            .sub-text 
            {
                color: #1A73E8;
                font-size: 18px;
                margin-bottom: 15px;
                line-height: 18px;
                text-transform: capitalize;
            }

            .time-duration 
            {
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                justify-content: space-between;
                -webkit-justify-content: space-between;
                align-items: flex-start;
                -webkit-align-items: flex-start;
    
                .timeline .timeline-text 
                {
                    color: #FFFFFF;
                    font-size: 15px;
                    background-color: #1A73E8;
                    -webkit-border-radius: 5px;
                    -moz-border-radius: 5px;
                    border-radius: 5px;
                    padding: 5px 10px 3px 10px;
                    line-height: 15px;
                }

            }
    
        }

        .time-duration .heading-text 
        {
            width: calc(65% - 15px);
        }

        .parentcontainer .left-box 
        {
            min-width: 65%;
        }

        .time-duration .normal-text 
        {
            width: calc(65% - 15px);
        }

        .timeline 
        {
            min-width: 35%;
            text-align: right;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: flex-end;
            justify-content: flex-end;
        }

        .custom-list li 
        {
            margin-left: 15px;
            position: relative;
            margin-bottom: 5px;
        }

        .separator 
        {
            padding: 20px 0;
            position: relative;
            width: 100%;
            display:block;

            &::before 
            {
                position: absolute;
                content: '';
                background-color: #00000033;
                height: 1px;
                width: 100%;
                left: 0;
            }

        }

        .spacer 
        {
            padding: 5px 0;
            width: 100%;
        }

        .normal-text 
        {
            margin-bottom: 15px;
        }

        .mb-10 
        {
            margin-bottom: 10px;
        }

        .tag-block .tag 
        {
            background-color: #F0F8FF;
            padding: 4px 10px 3px 10px;
            margin: 0 10px 10px 0;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            text-transform: capitalize;
        }

        .progress-bar 
        {
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 100%;
            background: #1A73E8;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
        }

        .progressbar-wrapper 
        {
            position: relative;
            width: 100%;
            height: 15px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            overflow: hidden;
            background-color: #F0F8FF;
        }

        .progress-block .progress-title 
        {
            margin-bottom: 10px;
    
            .progress-text 
            {
                font-size: 16px;
                text-transform: capitalize;
            }

        }

        .progress-block 
        {
            margin-bottom: 15px;
  
            .progress-title 
            {
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                justify-content: space-between;
                -webkit-justify-content: space-between;
    
                .progress-number 
                {
                    color: #1A73E8;
                    font-weight: 700;
                    font-size: 14px;
        
                }

            }        

        }

    }


    .ui-state-default,
    .ui-widget-content .ui-state-default,
    .ui-widget-header .ui-state-default,
    .ui-button,
    .ui-button.ui-state-disabled:active
    {
        border: 1px solid #c5c5c5;
        background: #f6f6f6;
        font-weight: normal;
        color: #454545;
        font-size: 16px;
        color: #333333;
        font-weight: 400;
        display: inline-block;
        line-height: normal;
    }    


    .ui-tabs-panel
    {
        width:100%;
        overflow: hidden;
    }


    .site-header
    {
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
        padding: 16px 0;
        background-color: #F5FAFF;    


        &.with-progress
        {
            .logo
            {
                margin: 0;
            }
        }


    }


    section
    {
        // padding-top:90px;
        display:block;
    }


    .bottom-sticky-opt
    {
        padding-bottom: 150px;
    }


    .s-py-half, .s-pt-half
    {
        padding-top: 90px !important;
    }


    .bordered-btn-blue 
    {
        color: #1A73E8;
        border-color: #1A73E8;

        &:focus, &:hover
        {
            background: #1A73E8;
            color: #fff;
        }

    }


    .bordered-btn
    {
        display: inline-block;
        min-width: 160px;
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
        color: #000;
        text-align: center;
        padding: 7px 24px;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;    


        &:focus, &:hover
        {
            color: #1A73E8;
            border-color: #1A73E8;
        }

    }


    .btn-weapper
    {

        .blue-btn, .bordered-btn, a 
        {
            margin-right: 25px;
        }

    }


    .blue-btn
    {
        display: inline-block;
        min-width: 160px;
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
        color: #fff;
        text-align: center;
        padding: 7px 24px;
        background-color: #1A73E8;
        border: 1px solid #1A73E8;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        cursor: pointer;

        &:focus, &:hover
        {
            color: #fff;
            background-color: #185abc;
            border-color: #185abc;
        }

    }


    .bordered-btn-blue 
    {
        color: #1A73E8;
        border-color: #1A73E8;

        &:focus, &:hover 
        {
            background: #1A73E8;
            color: #fff;
        }

    }


    .bordered-btn 
    {
        display: inline-block;
        min-width: 160px;
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
        color: #000;
        text-align: center;
        padding: 7px 24px;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius:5px;
        -webkit-border-radius:5px;
        -moz-border-radius:5px;

        &:focus, &:hover, .bordered-btn-blue 
        {
            color: #1A73E8;
            border-color: #1A73E8;
        }
    }

    
    .edit-list 
    {
        counter-reset: count;
        width:100%;
        padding-left:0px;

        > li 
        {
            background: #F5FAFF;
            position: relative;
            padding: 15px 15px 15px 60px;
            margin-bottom: 10px;
            font-size: 14px;
            line-height: normal;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;

            p
            {
                font-size: 14px;
                line-height: normal;
            }

            &:before 
            {
                counter-increment: count;
                content: counters(count, ".") " ";
                position: absolute;
                left: 15px;
                top: 15px;
                min-width: 28px;
                height: 28px;
                font-size: 16px;
                line-height: 28px;
                background: #1A73E8;
                color: #fff;
                text-align: center;
                border-radius: 28px;
                -webkit-border-radius: 28px;
                -moz-border-radius: 28px;
            }

            .detail 
            {
                width: 85%;
            }

            h3 
            {
                font-size: 20px;
                margin-bottom: 8px;
            }

            .info 
            {
                margin-bottom: 15px;
                display: inline-block;
            }

            .actions 
            {
                width: 15%;
                text-align: right;
    
                .actions
                {
                    a 
                    {
                        margin-right: 5px;
            
                        &:last-child 
                        {
                            margin-right: 0;
                        }
                    }
                }
            }

            .bullet-list 
            {

                li
                {
                    &:after 
                    {
                        top: 6px;
                    }
                }

            }

        }

    }


    .move-icon, .edit-icon, .delete-icon 
    {
        background: #fff;
        width: 35px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        display: inline-block;
        position: relative;
        border-radius: 35px;
        -webkit-border-radius: 35px;
        -moz-border-radius: 35px;
    }


    .move-icon:after, .edit-icon:after, .delete-icon:after 
    {
        font-family: 'fontello';
        font-size: 14px;
        text-align: center;
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
    }


    .move-icon:after 
    {
        content: "\e800";
        color: #7C7C7C;
    }


    .edit-icon:after 
    {
        content: "\e80b";
        color: #7C7C7C;
    }


    .delete-icon:after 
    {
        content: "\e80a";
        color: #E53F3F;
    }


    .move-icon:hover:after, .edit-icon:hover:after, .delete-icon:hover:after 
    {
        color: #fff;
    }


    .move-icon:hover, .edit-icon:hover 
    {
        background: #1A73E8;
    }


    .delete-icon:hover 
    {
        background: #E53F3F;
    }
    

    .resume-sticky-opt
    {
        background: #fff;
        position: fixed;
        z-index: 4;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 15px 0;
        border-top: 1px solid #ddd;

        .container
        {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
            align-items: center;
            -webkit-align-items: center;
            -ms-align-items: center;
            justify-content: space-between;
            -webkit-justify-content: space-between;
            -ms-justify-content: space-between;        
        }


    }


    .resume-tab
    {
        &.ui-tabs
        {
            .ui-tabs-nav 
            {
                margin-bottom: 40px;
            }

            .ui-tabs-panel 
            {
                padding: 0;
            }
        }
    }


    .ui-tabs 
    {
        .ui-tabs-nav 
        {
            padding: 0;
            border: 0;
            background: transparent;
            text-align: center;

            li 
            {
                float: none;
                background: transparent;
                display: inline-block;
                margin: 0 10px 0 0;
                border: 0;

                &:last-child 
                {
                    margin-right: 0;
                }

                &.ui-tabs-active {
                    padding: 0;
                    background: transparent;

                    .ui-tabs-anchor 
                    {
                        background-color: #1A73E8;
                        color: #fff;
                        border-color: #1A73E8;
                        cursor: pointer;
                    }

                }

            }

            .ui-tabs-anchor 
            {
                float: none;
                display: block;
                font-size: 14px;
                line-height: 24px;
                font-weight: 500;
                color: #000;
                text-align: center;
                padding: 7px 24px;
                background-color: #fff;
                border: 1px solid #ddd;
                border-radius:5px;
                -webkit-border-radius:5px;
                -moz-border-radius:5px;
            }

        }
    }


    .color-wrapper 
    {
        position: relative;


        .color-list 
        {
            display: block;

            li 
            {
                display: inline-block;
                vertical-align: top;
                margin-right: 5px;


                &:last-child 
                {
                    margin-right: 0;
                }
   

                &.active
                {

                    .color-box
                    {
    
                        &:after 
                        {
                            font-family: 'fontello';
                            content: "\e818";
                            font-size: 7px;
                            line-height: 22px;
                            color: #fff;
                            text-align: center;
                            position: absolute;
                            left: 0;
                            width: 100%;
                        }

                    }

                }


                .color-box 
                {
                    display: block;
                    width: 22px;
                    height: 22px;
                    position: relative;
                    cursor: pointer;
                    border-radius:22px;
                    -webkit-border-radius:22px;
                    -moz-border-radius:22px;
                }
            }
        }


        .selected-color 
        {
            position: relative;
            display: none;
            cursor: pointer;
            padding: 10px 30px 10px 10px;
            border: 1px solid #ddd;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;

            &:after 
            {
                content: "";
                position: absolute;
                pointer-events: none;
                margin-top: -2px;
                top: 50%;
                right: 10px;
                width: 0;
                height: 0;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 4px solid #7C7C7C;
            }

            span 
            {
                display: block;
                width: 22px;
                height: 22px;
                position: relative;
                border-radius:22px;
                -webkit-border-radius:22px;
                -moz-border-radius:22px;

                &:after
                {
                    font-family: 'fontello';
                    content: "\e818";
                    font-size: 7px;
                    line-height: 22px;
                    color: #fff;
                    text-align: center;
                    position: absolute;
                    left: 0;
                    width: 100%;
                }
            }

        }

    }
    

    .resume-sticky-opt .skip 
    {
        font-size: 15px;
        text-transform: uppercase;
        color: #000;
        opacity: .5;
        display: inline-block;
        margin-right: 20px;
        margin-left: auto;
    }


    .resume-sticky-opt .skip:hover 
    {
        color: #1A73E8;
        opacity: 1;
    }


    .blue-btn
    {
 
        i
        {

            &:after 
            {
                font-family: 'fontello';
                font-size: 13px;
                font-style: normal;
            }


            &.download-icon
            {

                &:after 
                {
                    content: "\e806";
                }

            }

        }

    }


    .text-center
    {
        text-align: center;
    }


    .text-right 
    {
        text-align: right;
    }


    .title 
    {
        margin: 0px auto;
        margin-bottom: 30px;

        &:last-child
        {
            margin-bottom: 0;
        }

        .icon
        {
            margin-bottom: 13px;
        }

        &.icon-center
        {
            .icon
            {
                display: inline-block;
            }
        }

        p 
        {
            font-size: 16px;
            line-height: 22px;
            color: #7C7C7C;
            font-weight: 400;
            margin-bottom: 20px;
        }

        &.space-between
        {
            flex-flow: row wrap;
            margin:0 -15px 30px -15px;
            width:100%;
        }

    }


    .ui-helper-hidden 
    {
        display: none;
    }


    .ui-helper-hidden-accessible 
    {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }


    .ui-helper-reset 
    {
        margin: 0;
        padding: 0;
        border: 0;
        outline: 0;
        line-height: 1.3;
        text-decoration: none;
        font-size: 100%;
        list-style: none;
    }


    .ui-helper-clearfix:before, .ui-helper-clearfix:after 
    {
        content: "";
        display: table;
        border-collapse: collapse;
    }


    .ui-helper-clearfix:after 
    {
        clear: both;
    }
    

    .ui-helper-zfix 
    {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        opacity: 0;
        filter:Alpha(Opacity=0); /* support: IE8 */
    }
    

    .ui-front 
    {
        z-index: 100;
    }
    

    h1 
    {
        font-family: 'Roboto', sans-serif;
        font-size: 44px;
        font-weight: 400;
        line-height: 52px;
        color: #000;
        margin-bottom: 10px;
    }


    h2 
    {
        font-family: 'Roboto', sans-serif;
        font-size: 30px;
        font-weight: 400;
        line-height: 35px;
        color: #000;
        margin-bottom: 10px;
    }


    h3 
    {
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        color: #000;
        margin-bottom: 10px;
    }


    h4 
    {
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: #000;
        margin-bottom: 10px;
    }


    h5 
    {
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #000;
        margin-bottom: 10px;
    }


    h6 
    {
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: normal;
        color: #7D7D7D;
        letter-spacing: 2px;
        text-transform: uppercase;
        margin-bottom: 10px;
    }


    label 
    {
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        display: block;
        margin-bottom: 5px;
    }

        
    .bullet-list
    {
        list-style: none;
        padding: 0;

        li 
        {
            position: relative;
            padding-left: 15px;
            margin: 0 0 10px 0;

            &:last-child
            {
                margin-bottom: 0;
            }

            &:after
            {
                position: absolute;
                left: 0;
                top: 10px;
                pointer-events: none;
                content: "";
                width: 4px;
                height: 4px;
                background: #7C7C7C;
                border-radius: 4px;
                -webkit-border-radius: 4px;
                -moz-border-radius: 4px;
            }

        }

    }


    .delete
    {
        display: inline-block;
        position: relative;
        width: 30px;
        height: 30px;
        margin-right: 10px;
        color: #fff;
        background: #DC1818;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;

        &:hover
        {
            color: #fff;
            background: #DC1818;
        }

        &:after 
        {
            font-family: 'fontello';
            content: "\e80a";
            font-size: 14px;
            line-height: 30px;
            text-align: center;
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

    }


    .add
    {
        position: relative;
        line-height: 30px;
        padding-left: 40px;
        display: inline-block;

        &:hover
        {
            color: #11de5e;
 
            &:after
            {
                color: #fff;
                border-color: #11de5e;
                background: #11de5e;
            }

       }

        &:after
        {
            font-family: 'fontello';
            content: "\e807";
            font-size: 12px;
            line-height: 28px;
            text-align: center;
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 30px;
            height: 30px;
            background: #F5FAFF;
            border: 1px solid #ddd;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            transition: all 0.3s ease;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
        }

    }


    .custom-checkbox
    {
        display: inline-block;
        vertical-align: top;
        margin: 0 20px 10px 0;

        :last-child
        {
            margin: 0;
        }

        input[type="checkbox"] 
        {
            display: none;
        }

        input[type="checkbox"] + label
        {
            font-size: 14px;
            color: #7C7C7C;
            line-height: 25px;
            padding:0 0 0 40px;
            margin: 0;
            min-height: 25px;
            position: relative;
            display: inline-block;
            cursor: pointer;

            &:before, &:after
            {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 25px;
                height: 25px;
                cursor:pointer;
                pointer-events: none;
                border-radius: 3px;
                -webkit-border-radius: 3px;
                -moz-border-radius: 3px;
                -webkit-transition:all 0.1s ease-in;
                -moz-transition:all 0.1s ease-in;
                -o-transition:all 0.1s ease-in;
                transition:all 0.1s ease-in;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                -o-box-sizing: border-box;
                box-sizing: border-box;
            }

            &:before
            {
                background: #fff;
                border:1px solid #E0E0E0;
            }

            &:after 
            {
                opacity: 0;
                font-family: 'fontello';
                content: "\e818";
                font-size: 9px;
                line-height: 25px;
                color: #fff;
                text-align: center;
            }

        }

        input[type="checkbox"]
        {

            &:checked + label
            {
                &:after 
                {
                    opacity: 1;
                }

                &:before
                {
                    background: #1A73E8;
                    border-color: transparent;
                }

            }

        }


    }    


    .fw-500
    {
        font-weight:500;
    }


    .resume-slider
    {
        li
        {
            position: relative;
            cursor: pointer;
            
            &.selected
            {
                img
                {
                    border: 2px solid #1A73E8;
                }

                &:before, &:after
                {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: inherit;            
                }

            }

            &:before
            {
                content: "";
                position: absolute;
                z-index: 1;
                top: 0;
                right: 0;
                width: 0;
                height: 0;
                border-top: 60px solid #1A73E8;
                border-left: 60px solid transparent;
                opacity: 0;
                visibility: hidden;
                pointer-events: none;                
            }

            &:after
            {
                font-family: 'fontello';
                content: "\e818";
                font-size: 11px;
                line-height: normal;
                z-index: 1;
                color: #fff;
                position: absolute;
                top: 12px;
                right: 10px;
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
                transition: all 0.3s ease;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
            }

            .zoom-icon
            {
                position: absolute;
                bottom: 20px;
                right: 20px;
                width: 45px;
                height: 45px;
                background: #1A73E8;
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
                border-radius: 100%;
                -webkit-border-radius: 100%;
                -moz-border-radius: 100%;
                transition: all 0.3s ease;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;

                &:after
                {
                    font-family: 'fontello';
                    content: "\e817";
                    font-size: 20px;
                    line-height: 45px;
                    color: #fff;
                    text-align: center;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    margin-left:-50%;
                }
                
            }

            &:hover
            {
                .zoom-icon
                {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: inherit;
                }
            }

        }
    }


    .text-field
    {
        width: 100%;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-weight: 400;
        color: #000;
        background: #fff;
        border: 1px solid #D9D9D9;
        padding: 14px 16px;
        border-radius:5px;
        -webkit-border-radius:5px;
        -moz-border-radius:5px;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        -webkit-transition:all 0.3s ease-in-out;
        -moz-transition:all 0.3s ease-in-out;
        -o-transition:all 0.3s ease-in-out;
        transition:all 0.3s ease-in-out;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        appearance: none;
    }


    .form-list
    {
        display: flex;
        flex-wrap: row wrap;
        margin: 0 -8px;
        padding: 0;
        list-style: none;
        color: #7c7c7c;

        li
        {
            width: 100%;
            padding: 0 8px;
            margin-bottom: 16px;
            position: relative;
        }


        .half
        {
            width: 50%;


            label
            {
                font-size: 14px;
                font-weight: 500;
                line-height: normal;
                display: block;
                margin-bottom: 5px;
            }


        }


        > .degree
        {

            .delete
            {
                display: none;
            }

        }


    }


    .space-between
    {
        justify-content: space-between;
        -webkit-justify-content: space-between;
        -ms-justify-content: space-between;        
    }
    

    .resume-preview
    {
        max-width: 250px;
        text-align: center;
        

        img
        {
            display: block;
            max-width: 100%;
        }


    }


    .resume-dropdown-menu
    {
        position: relative;

        .droupdown 
        {
            position: absolute;
            z-index: 11;
            display: none;
            top: calc(100% - 15px);
            left: -250px;
            padding: 20px;
            width: 320px;
            background: #fff;
            border: 1px solid #ddd;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;

            p
            {
                font-size:14px;
                font-weight: 500;
            }

        }

    }
    

    .action-icon
    {
        
        li 
        {
            display: inline-block;
            margin-right: 10px;
            text-align: left;

            &:last-child 
            {
                margin-right: 0;
            }

        }


        a 
        {
            display: block;
            width: 32px;
            height: 32px;
            background: #F5FAFF;
            color: #7C7C7C;
            border-radius: 32px;
            -webkit-border-radius: 32px;
            -moz-border-radius: 32px;
            position: relative;

            &:hover 
            {
                background: #185abc;
                color: #fff;
            }
        }


        .preview-icon
        {

            &:after 
            {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                content: "\e815";
                font-family: 'fontello';
                font-size: 10px;
                text-align: center;
                line-height: 32px;
            }

        }


        .help-icon
        {

            &:after 
            {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                content: "\e814";
                font-family: 'fontello';
                font-size: 16px;
                text-align: center;
                line-height: 32px;
            }

            &:hover
            {
                .droupdown
                {
                    display: block;
                }
            }

        }
    }


    .search-box 
    {
        position: relative;

        .search-icon 
        {
            position: absolute;
            top: 14px;
            right: 15px;
            cursor: pointer;

            &:after 
            {
                font-family: 'fontello';
                font-size: 17px;
                content: "\e80c";
                color: #C8C8C8;
            }

        }

    }


    .example-box 
    {
        width: calc(100% + 20px);
        height: 308px;
        overflow-y: auto;

        .mCSB_inside
        {

            >.mCSB_container 
            {
                margin-right: 0;
            }
        }


        .example-list 
        {
            padding-left: 0px;
            padding-right: 20px;

            li 
            {
                background: #fff;
                border: 1px solid #ddd;
                padding: 12px 15px;
                margin-bottom: 4px;
                position: relative;
                cursor: pointer;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                align-items:center;
                -webkit-align-items:center;
                -ms-align-items:center;
                border-radius: 4px;
                -webkit-border-radius: 4px;
                -moz-border-radius: 4px;


                &:last-child 
                {
                    margin-bottom: 0;
                }


                &.selected 
                {
                    border-color: #1A73E8;

                    .icon 
                    {
                        background: #E53F3F;
    
                        &:before
                        {
                            top: -2px;
                            opacity: 0;
                        }

                    }

                }

        
                .icon 
                {
                    display: block;
                    min-width: 25px;
                    height: 25px;
                    margin-right: 10px;
                    position: relative;
                    background: #1A73E8;
                    border-radius: 25px;
                    -webkit-border-radius: 25px;
                    -moz-border-radius: 25px;
                    transition: all 0.3s ease;
                    -webkit-transition: all 0.3s ease;
                    -moz-transition: all 0.3s ease;

                    &:after, &:before 
                    {
                        content: "";
                        position: absolute;
                        background: #fff;
                        transition: all 0.3s ease;
                        -webkit-transition: all 0.3s ease;
                        -moz-transition: all 0.3s ease;
                    }

                    &:after 
                    {
                        width: 12px;
                        height: 2px;
                        top: 12px;
                        left: 7px;
                    }

                    &:before 
                    {
                        width: 2px;
                        height: 12px;
                        top: 7px;
                        left: 12px;
                    }

                }
                
                .text 
                {
                    font-size: 14px;
                    line-height: normal;
                    color: #7C7C7C;
                    transition: all 0.3s ease;
                    -webkit-transition: all 0.3s ease;
                    -moz-transition: all 0.3s ease;
                }    

            }

        }

    }


    .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
    .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
    .mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
    .mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar 
    {
        background-color: #B6C0CB;
    }


    .mCSB_scrollTools .mCSB_draggerRail 
    {
        width: 4px;
        background-color: #D9E3EC;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
    }


    .editor-block 
    {
        width: 100%;
        border: 1px solid #ddd;
        /*order: 2;*/
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;

        .editor-tools 
        {
            padding: 20px;

            li 
            {
                display: inline-block;
                margin-right: 15px;

                &:last-child 
                {
                    margin-right: 0;
                }

                a 
                {
                    display: block;
                    color: #fff;

                    &:after 
                    {
                        font-family: 'fontello';
                        font-size: 15px;
                        color: #7C7C7C;
                        transition: all 0.3s ease;
                        -webkit-transition: all 0.3s ease;
                        -moz-transition: all 0.3s ease;
                    }

                    &:hover
                    {
                        &:after 
                        {
                            color: #1A73E8;
                        }

                    }
                    
                    &.tool-bold:after 
                    {
                        content: "\e80d";
                    }

                    &.tool-italic:after 
                    {
                        content: "\e80f";
                    }

                    &.tool-underline:after 
                    {
                        content: "\e812";
                    }

                    &.tool-list:after 
                    {
                        content: "\e810";
                    }

                    &.tool-grammar:after 
                    {
                        content: "\e80e";
                    }

                    &.tool-undo:after 
                    {
                        content: "\e813";
                    }

                    &.tool-redo:after 
                    {
                        content: "\e811";
                    }
                    
                }

            }

        }

    }


    .text-editor 
    {
        height: 390px;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 20px;
        background: #fff;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        font-size: 14px;
        line-height: normal;
    
        p 
        {
            font-size: 14px;
            line-height: normal;
        }

        ul 
        {
            padding-left: 20px;

            li 
            {
                margin-bottom: 10px;
                list-style: disc;
            }

        }

        p 
        {
            margin-bottom: 10px;

            &:last-child 
            {
                margin-bottom: 0;
            }

        }

        &:focus 
        {
            outline: none;
        }    

    }


    div.text-editor[placeholder]:empty:before {
        content: attr(placeholder);
        color: #A6A6A6;
    }
    

    div.text-editor[placeholder]:empty:focus:before {
        content: "";
    }


    .light-blue-bg 
    {
        background: #F5FAFF;
    }


    .mb-20 
    {
        margin-bottom: 20px !important;
    }

    
    .signup-form
    {
        max-width: 434px;
        margin: 0 auto;

        .form-list 
        {
            text-align: left;
        }

        .light-blue-bg 
        {
            padding: 30px;
        }

    }


    .or 
    {
        display: block;
        color: #000;
        text-align: center;
        text-transform: uppercase;
        margin: 15px 0;
    }


    .other-opt 
    {
        margin-bottom: 30px;

        a 
        {
            display: block;
            text-align: center;
            color: #fff;
            position: relative;
            padding: 15px 30px;
            margin-bottom: 10px;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            
            &:last-child
            {
                margin-bottom: 0;
            }
        }

        .with-fb 
        {
            background: #1877F2;

            &:hover 
            {
                background: #2169c6;
            }

            &:before 
            {
                display: inline-block;
                vertical-align: middle;
                margin-right: 10px;
                content: "";
                width: 18px;
                height: 18px;
                background: url(./../../../images/facebook.svg) center center no-repeat;
                background-size: 18px auto;
            }
        }

        .with-google
        {
            background: #EA4335;

            &:hover 
            {
                background: #CA4439;
            }

            &:before 
            {
                display: inline-block;
                vertical-align: middle;
                margin-right: 10px;
                content: "";
                width: 18px;
                height: 18px;
                background: url(./../../../images/google.svg) center center no-repeat;
                background-size: 18px auto;
            }

        }

    }

    
    .skill-holder
    {
        position: relative;
        min-height: 454px;
        padding: 20px;
        background: #fff;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;

        .add 
        {
            position: absolute;
            right: 20px;
            bottom: 20px;
        }
        
        .skill-list 
        {
            padding-left: 0;

            li 
            {
                background: #F5FAFF;
                padding: 6px 15px;
                margin-bottom: 4px;
                position: relative;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                align-items:center;
                -webkit-align-items:center;
                -ms-align-items:center;
                border-radius: 5px;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                
                .name 
                {
                    font-size: 14px;
                    color: #000;
                    padding-right: 10px;
                    -ms-flex-preferred-size:0;
                    flex-basis:0;
                    -webkit-box-flex:1;
                    -ms-flex-positive:1;
                    flex-grow:1;
                    max-width:100%;

                    .text-field 
                    {
                        padding: 10px 16px;
                    }

                }

                .action 
                {
                    max-width: 35px;
        
                    .delete-icon 
                    {
                        width: 32px;
                        height: 32px;
                    }

                }

            }

        }

    }

    .rating-box 
    {
        margin-right: 10px;

        .star
        {
            width: 15px;
            height: 15px;
            margin-right: 5px;
            display: inline-block;
            vertical-align: top;
            position: relative;
            cursor: pointer;
            transition: all 0.3s ease;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;

            &::after
            {
                font-family: 'fontello';
                content: "\e809";
                font-size: 15px;
                color: #CDCDCD;
                position: absolute;
                top: 0;
                left: 0;
                transition: all 0.3s ease;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                box-sizing: border-box;
            }

            &.active, &:hover
            {
                &::after
                {
                    color: #FFCB15;
                }
            }
        }
    }


    .add-own-skill 
    {
        display: none;
    }
    

    .checkbox-list
    {
        list-style: none;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-flow: row wrap;
        flex-flow: row wrap;
        margin-left: -15px;
        margin-right: -15px;

        li
        {
            width: 100%;
            padding: 0 15px;
            margin-bottom: 15px;

            .half 
            {
                width: 50%;
            }

            .custom-checkbox 
            {
                display: block;
            }

        }

    }


    .new-sections
    {
        margin-bottom: 10px;
        display: none;

        .sections-wrapper 
        {
            margin-top: 10px;
            padding-left: 40px;
            position: relative;

            &:first-child .delete
            {
                opacity: .5;
                pointer-events: none;
                cursor: not-allowed;
            }
            
        }

        .add 
        {
            margin-top: 10px;
        }

        .delete 
        {
            position: absolute;
            top: 10px;
            left: 0;
        }

    }


    .pricing-page
    {

        h1 span
        {
            color: #1A73E8;
        }

        .s-pt, .s-py
        {
            padding-top: 100px !important;
        }

        .s-pb, .s-py
        {
            padding-bottom: 100px !important;
        }

        .show-in-mobile 
        {
            display: none !important;
        }

        .list-style-1 
        {
            list-style: none;
            padding: 0;
            margin-bottom: 30px;
            text-align: left;
    
            li 
            {
                font-size: 16px;
                position: relative;
                padding-left: 25px;
                margin-bottom: 10px;
        
                &:before 
                {
                    content: "\e818";
                    font-family: 'fontello';
                    font-size: 6px;
                    line-height: 18px;
                    color: #fff;
                    text-align: center;
                    position: absolute;
                    top: 2px;
                    left: 0;
                    width: 18px;
                    height: 18px;
                    background: #1A73E8;
                    border-radius: 18px;
                    -webkit-border-radius: 18px;
                    -moz-border-radius: 18px;
                }
            }
    
            &:last-child, li:last-child 
            {
                margin-bottom: 0;
            }
        }

        .pricing-section .container 
        {
            width: 850px;
        }

        .pricing-plan 
        {
            margin-left: -25px;
            margin-right: -25px;
            width:800px;
    
            .box
            {
                text-align: center;
                width:calc(50% - 50px);
                margin: 0 25px;
                padding: 30px;
                border: 1px solid rgba(0, 0, 0, 0.05);
                background: #f5faff;
                position: relative;
                border-radius: 20px;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                transition: all 0.3s ease;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
        
                &:after 
                {
                    position: absolute;
                    content: "";
                    pointer-events: none;
                    width: 130px;
                    height: 86px;
                    bottom: 0;
                    left: 50%;
                    margin-left: 45px;
                    background: url(./../../../images/arrow.png) center center no-repeat;
                    background-size: 100% auto;
                    display: none;
                }
        
                &.active:after 
                {
                    display: block;
                }
        
                .blue-btn 
                {
                    width: 250px;
                    max-width: 100%;
                }
        
                &.active 
                {
                    padding: 30px 30px 50px 30px;
                    background: #1A73E8;
                    color: #fff;
                }
        
                &.active .blue-btn 
                {
                    background: #fff;
                    color: #1A73E8;
                }
        
                &.active .list-style-1 li:before 
                {
                    background: #fff;
                    color: #1A73E8;
                }
        
                &.active h2 
                {
                    color: #fff;
                }
        
                .title 
                {
                    padding: 0 0 20px 0;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                }
        
                .tag 
                {
                    position: absolute;
                    top: -14px;
                    left: 50%;
                    background: #fff;
                    border: 1px solid #e2effc;
                    padding: 5px 10px;
                    width: 120px;
                    font-size: 12px;
                    color: #1A73E8;
                    text-align: center;
                    text-transform: uppercase;
                    -webkit-transform: translatex(-50%);
                    transform: translatex(-50%);
                    border-radius: 20px;
                    -webkit-border-radius: 20px;
                    -moz-border-radius: 20px;
                }
        
                h2 
                {
                    text-transform: uppercase;
                }
            }
        }

        .cta-section .red-btn:not(:last-child) 
        {
            margin-bottom: 20px;
        }
        
        .testimonial-section .container 
        {
            width: 1600px;
        }

        .testimonial-slider .slick-track 
        {
            padding: 50px 0;
        }

        .testimonial-slider .item 
        {
            background: #fff;
            padding: 30px;
            opacity: .8;
            position: relative;
            border-top: 5px solid transparent;
            transition: all 0.3s ease;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
        }

        .testimonial-slider .slick-slide.slick-current .item 
        {
            z-index: 1;
            opacity: 1;
            border-top: 5px solid #1A73E8;
            -webkit-transform: scale(1.20);
            -moz-transform: scale(1.20);
            transform: scale(1.20);
            -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.05);
            -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.05);
            box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.05);
        }

        .testimonial-slider .item .author-detail 
        {
            margin-bottom: 20px;
            padding-right: 100px;
            align-items:center;
            -webkit-align-items:center;
            -ms-align-items:center;
        }

        .testimonial-slider .item .author-detail .thumb img 
        {
            max-width: 70px;
            border-radius: 70px;
            -webkit-border-radius: 70px;
            -moz-border-radius: 70px;
        }

        .testimonial-slider .item .author-detail .detail 
        {
            padding-left: 20px;
        }

        .testimonial-slider .item .author-detail h4 
        {
            margin-bottom: 0;
        }

        .testimonial-slider .item .author-detail p 
        {
            font-size: 14px;
            color: #1A73E8;
            line-height: normal;
        }

        .testimonial-slider .item .slide-logo 
        {
            max-width: 100px;
            position: absolute;
            top: 30px;
            right: 30px;
        }

        .testimonial-slider .item .slide-logo img 
        {
            max-height: 60px;
        }

        .testimonial-slider .item p 
        {
            line-height: 26px;
            color: #000;
        }    

        @media (max-width: 767px) 
        {
            .show-in-mobile 
            {
                display: block !important;
            }

            .hide-in-mobile 
            {
                display: none !important;
            }

            /* section padding */
            .s-pt, .s-py 
            {
                padding-top: 30px !important;
            }

            .s-pb, .s-py 
            {
                padding-bottom: 30px !important;
            }

            .s-pt-half, .s-py-half 
            {
                padding-top: 20px !important;
            }

            .s-pb-half, .s-py-half 
            {
                padding-bottom: 20px !important;
            }

            .bottom-sticky-opt 
            {
                padding-bottom: 100px;
            }

            /* section padding */
            .title 
            {
                margin-bottom: 20px;
            }

            .title h1 br, .title h2 br, .title p br 
            {
                display: none;
            }

            h1 
            {
                font-size: 28px;
                line-height: 36px;
            }

            h2 
            {
                font-size: 25px;
                line-height: 30px;
            }

            .sub-text 
            {
                font-size: 16px;
            }

            .site-header 
            {
                position: relative;
                top: 0;
            }

            .page-final .site-header .blue-btn 
            {
                padding: 5px 20px;
            }

            .logo 
            {
                max-width: 80px;
            }

            .content-wrapper 
            {
                padding: 0 0 217px 0;
            }

            .v-middle 
            {
                align-items: flex-start;
                -webkit-align-items: flex-start;
                -ms-align-items: flex-start;
                -webkit-align-content: flex-start;
                -ms-align-content: flex-start;
                align-content: flex-start;
                min-height: calc(100vh - 297px);
            }

            .col-3-icon li 
            {
                width: 100%;
                padding: 0 90px;
                margin-bottom: 20px;
            }

            .col-3-icon li:last-child 
            {
                margin-bottom: 0;
            }

            .col-3-icon li .icon 
            {
                max-width: 45px;
                margin-bottom: 10px;
            }

            .footer-top 
            {
                padding: 50px 0 30px 0;
            }

            .footer-logo 
            {
                max-width: 80px;
                display: block;
                margin: 0 auto 20px auto;
            }

            .footer-menu 
            {
                width: 100%;
                text-align: center;
                margin-bottom: 20px;
            }

            .footer-menu li 
            {
                margin: 0 7px;
            }

            .footer-social
            {
                width: 100%;
                text-align: center;
            }

            .footer-bottom .copyright 
            {
                width: 100%;
                text-align: center;
                margin-bottom: 20px;
            }

            .footer-bottom .logos 
            {
                width: 100%;
                text-align: center;
            }

            .resume-slider .slick-slide, .coverletter-slider .slick-slide 
            {
                padding: 0 10px;
            }

            /*.resume-sticky-opt.choose-template-stickybar .left-block,
            .resume-sticky-opt.choose-template-stickybar .right-block {
                width: 100%;
                text-align: center;
            }
            .resume-sticky-opt.choose-template-stickybar .left-block {
                margin-bottom: 10px;
            }*/

            .resume-sticky-opt.choose-template-stickybar .o
            {
                text-align: center;
            }

            .color-wrapper .selected-color 
            {
                display: inline-block;
                vertical-align: top;
            }

            .color-wrapper .color-list 
            {
                display: none;
                position: absolute;
                bottom: 100%;
                width: 100%;
                max-height: 200px;
                overflow-y: auto;
                background: #fff;
                border: 1px solid #ddd;
                border-radius: 5px;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
            }

            .color-wrapper .color-list li 
            {
                display: block;
                margin: 0;
                padding: 5px;
                border-bottom: 1px solid #ddd;
            }

            .color-list li .color-box 
            {
                margin: 0 auto;
            }

            .resume-sticky-opt .skip 
            {
                margin-right: 10px;
            }

            .progress-block 
            {
                font-size: 0;
                line-height: normal;
            }

            .progress-block .mobile-progress-title 
            {
                display: block;
                margin-bottom: 2px;
            }

            .progress-block .step 
            {
                padding: 0;
                margin: 0;
                width: 25px;
                font-size: 0;
                height: 2px;
            }

            .progress-block .step:after
            {
                width: 100%;
                height: 2px;
                top: 0;
            }

            .progress-block .step:last-child:after
            {
                display: block;
            }

            .progress-block .step.visited:after, .progress-block .step.active:after 
            {
                background: #1A73E8;
            }

            .progress-block .step:before 
            {
                display: none;
            }

            .select-box-wrapper .select-box 
            {
                width: 100%;
                margin-bottom: 20px;
            }

            .select-box-wrapper .select-box:last-child 
            {
                margin-bottom: 0;
            }

            .select-box-wrapper .select-box .icon 
            {
                max-width: 45px;
                margin-bottom: 10px;
            }

            .fancybox-slide--html .fancybox-close-small 
            {
                top: 10px;
                right: 10px;
            }

            .popup-header 
            {
                padding: 20px 40px 20px 20px;
            }

            .popup-content 
            {
                padding: 20px;
            }

            .upload-wrapper 
            {
                padding: 30px;
            }

            .upload-resume-popup .popup-content .btn-weapper .bordered-btn 
            {
                padding-left: 20px;
                padding-right: 20px;
            }

            .popup-footer 
            {
                padding: 20px;
            }

            .resume-list li 
            {
                width: 50%;
            }

            .mobile-progress-track 
            {
                display: block;
            }

            .progress-bar li
            {
                text-align: left;
                padding-top: 10px;
            }

            .progress-bar li:after, .progress-bar li.visted.active + li:after 
            {
                height: 8px;
                top: 0;
                left: 0;
            }

            .progress-bar li:first-child:after 
            {
                content: "";
            }

            .progress-bar li .icon 
            {
                display: none;
            }

            .progress-bar li .progress-title 
            {
                display: none;
            }

            .editor-wrapper .editor-block 
            {
                width: 100%;
            }

            .editor-wrapper .example-block 
            {
                width: 100%;
                order: 2;
            }

            .checkbox-list li.half 
            {
                width: 100%;
            }

            .in-school li 
            {
                -ms-flex-preferred-size:inherit;
                flex-basis:inherit;
                -webkit-box-flex:inherit;
                -ms-flex-positive:inherit;
                flex-grow:inherit;
                max-width:inherit;
                width: 50%;
            }

            .working-style-list .item 
            {
                width: calc(50% - 20px);
            }

            .action-icon li 
            {
                margin-right: 5px;
            }

            /*.page-final .template-holder {
                -webkit-transform: scale(.53);
                transform: scale(.53);
                -webkit-transform-origin: 0 0 0;
                transform-origin: 0 0 0;
            }*/

            .formatting-opt .col-3 
            {
                width: 50%;
            }

            .pricing-plan 
            {
                margin: 0;
            }

            .pricing-plan .box 
            {
                width: 100%;
                margin: 0 0 30px 0;
            }

            .pricing-plan .box:last-child 
            {
                margin-bottom: 0;
            }

            .pricing-section h1 
            {
                font-size: 30px;
                font-weight: bold;
            }

            .pricing-section .title br 
            {
                display: block;
            }

            .pricing-plan .list-style-1 li 
            {
                font-weight: 500;
            }

            .pricing-plan .box h2 
            {
                font-size: 26px;
                font-weight: 700;
            }

        }
        
    }


    @media (max-width: 1023px)
    {

        .resume-sticky-opt
        {

            .container
            {
                padding-left: 30px;
                padding-right: 30px;
            }

        }

    }


    @media (max-width: 1199px) 
    {

        h1 
        {
            font-size: 36px;
            line-height: 44px;
        }

    }


}

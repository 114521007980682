@media  (max-width: 1023px)
{
    .resume-builder > section
    {
        z-index: 11;
    }

    .page-final .preview-weapper > .left-block, .page-final .resume-sticky-opt 
    {
        position: fixed;
        z-index: 99999;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.1);
        opacity: 1;
        visibility: hidden;
        width: 100% !important;
        height: 100% !important;
        transition: all 0.3s ease;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
    }

    .side-menu-open
    {
        visibility: visible !important;
    }

    .sidebar
    {
        background-color: rgb(255, 255, 255);
        top:0px;
        left:0px;
        width:90%;
        height:100%;
        margin: 0 0 0 -15px;
        padding: 0 0 0 15px;
    }

    .resume-builder .page-final .sidebar ul
    {
        padding-top: 20px;
    }


    .resume-builder .page-final .sidebar .close
    {
        display: block;
    }

    .resume-builder .page-final .template-header .more-opt
    {
        display: none;
    }

    .page-final .preview-weapper > .right-block .template-wrapper
    {
        display:none;
    }

    .resumePreview .overlayContainer .resumePreviewWindow
    {
        height:800px;
        width:500px;
        -ms-zoom: 0.6;
        -moz-transform: scale(0.6);
        -moz-transform-origin: 0 0;
        -o-transform: scale(0.6);
        -o-transform-origin: 0 0;
        -webkit-transform: scale(0.6);
        -webkit-transform-origin: 0 0;
    }

    .page-final .preview-weapper > .right-block .preview-wrapper
    {
        display: block !important;
    }

    .resume-builder .btn-weapper a.final-button, .resume-builder .btn-weapper .bordered-btn
    {
        margin-right: 5px;
    }
    
    .non-mobile { display: none !important; }
    .mobile { display: inline-block !important; }
}


@media (max-width: 767px)
{
    .resume-builder .site-header
    {
        padding: 10px 0;
        position: relative;
        top: 0;
    }
}


@media (max-width: 575px)
{
    .resume-builder h2
    {
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 5px;
    }

    .resume-builder .title p
    {
        font-size: 14px;
        line-height: 20px;
    }

    .resume-builder .form-list .half
    {
        width: 100%;
    }

    .resume-builder .form-list .half label 
    {
        width: 100%;
    }
    
    .resume-builder .title.space-between
    {
        width: auto;
    }

    .resume-builder .col
    {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
    }

    .resume-builder .col-auto
    {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }

    .resume-builder .resume-step-temporary
    {
        margin-top: 50px;
    }

    .mCSB_container
    {
        max-height:300px;
        overflow-y:scroll;
    }

}


@media (max-width: 400px)
{
    .resume-builder .container > .logo > a > img
    {
        max-width: 80px;
    };

    .resume-builder .s-py-half, .resume-builder .s-pt-half 
    {
        padding-top: 15px !important;
    }

    .resume-slider > ul > li > img
    {
        width: 350px !important;
        margin-left: 20px;
    }

    .resume-builder .container {
        padding-left: 15px;
        padding-right: 15px;
    }

    .resume-builder h1
    {
        font-size: 26px;
        line-height: 34px;
    }

    .resume-builder .container .progress-block .mobile-progress-title
    {
        display: block;
    }

    .resume-builder .container .progress-block .step 
    {
        padding: 0;
        margin: 0;
        width: 25px;
        font-size: 0;
        height: 2px;
    }

    .resume-builder .container .progress-block .step:before 
    {
        content: "";
        display: none;
    }

    .resume-builder .container .progress-block .step:after 
    {
        content: "";
        width: 100%;
        height: 2px;
        top: 0;
    }
    .resume-builder .container .progress-block .step.visited:after,
    .resume-builder .container .progress-block .step.active:after 
    {
        background: #1A73E8;
    }

}
@font-face {
    font-family: 'fontello';
    src: url('./fonts/fontello.eot?8542392');
    src: url('./fonts/fontello.eot?8542392#iefix') format('embedded-opentype'),
         url('./fonts/fontello.woff2?8542392') format('woff2'),
         url('./fonts/fontello.woff?8542392') format('woff'),
         url('./fonts/fontello.ttf?8542392') format('truetype'),
         url('./fonts/fontello.svg?8542392#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
  }